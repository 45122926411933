import React, { Fragment, useState } from "react";
import { SelectOptionsInput } from "./SelectOptionsInput";
import { FormGroup, Input, Label, Row, FormFeedback } from "reactstrap";
import { _Colors } from "../../../../../../Jss-styles/_variables";

//ICI on définit la composition de chaque champ de formulaire

export const GenderOptions = ({ isOpen, handleSelect, value }) => {
  return (
    <SelectOptionsInput name="gender" value={value} handleSelect={handleSelect}>
      <option value="">Choisir la classe</option>
      <option value="girls" disabled={!isOpen("girls")}>
        Classe des filles {isOpen("girls") === false && "(complet)"}{" "}
      </option>
      <option value="boys" disabled={!isOpen("boys")}>
        Classe des garçons {isOpen("boys") === false && "(complet)"}
      </option>
    </SelectOptionsInput>
  );
};

export const SlotsOptions = (props) => {
  const { handleSelect, value, handleByGender, formData, stock } = props;

  //fonction qui détermine si l'option de l'input select est disponible ou non
  //avant, on utilisait la prop isOpen - maintenant on importe une prop stock
  const isOpen = (slot) => {
    if (handleByGender) {
      if (formData["gender"] !== "") {
        return stock[formData["gender"]][slot];
      }
      return true;
    } else if (formData["branch"]) {
      return stock[formData["branch"]][slot];
    } else {
      return stock[slot];
    }
  };
  return (
    <SelectOptionsInput name="slots" value={value} handleSelect={handleSelect}>
      <option value="">Choisir le créneau horaire</option>
      <option
        disabled={!isOpen("morning")}
        value={isOpen("morning") ? "morning" : ""}
      >
        Matin {!isOpen("morning") && "(classe complète)"}
      </option>
      <option
        disabled={!isOpen("afternoon")}
        value={isOpen("afternoon") ? "afternoon" : ""}
      >
        Après-midi {isOpen("afternoon") === false && "(classe complète)"}
      </option>
    </SelectOptionsInput>
  );
};

export const Lv2Options = ({ stock, handleSelect, formData }) => {
  //LVB seulement pour cursus classique et bilingue car pour le cursus double LVB Arabe d'office
  const info = (
    <small>
      *LVB Arabe : le niveau intermédiaire (lecture, écriture et compréhension)
      est requis.
    </small>
  );
  const isAvailable = (lv2) => {
    //le stock est de type arabe->gender ou bien de type arabe->gender->slot
    //07.09 : ou de type arabe->branch->gender (lycée)

    if (stock[lv2] && formData["gender"] !== "") {
      if (typeof stock[lv2][formData["gender"]] === "boolean") {
        return stock[lv2][formData["gender"]];
      } else if (formData["slots"] !== "") {
        return stock[lv2][formData["gender"]][formData["slots"]];
      } else if (formData["branch"] !== "" && stock[lv2][formData["branch"]]) {
        return stock[lv2][formData["branch"]][formData["gender"]];
      }
    }
    return true;
  };

  return (
    <SelectOptionsInput
      name="lv2"
      value={formData["lv2"]}
      handleSelect={handleSelect}
      info={info}
    >
      <option value="">Choisir la LVB*</option>

      <option
        value={isAvailable("arabe") ? "arabe" : ""}
        disabled={!isAvailable("arabe")}
      >
        Arabe {!isAvailable("arabe") && "(complet)"}
      </option>
      <option
        value={isAvailable("espagnol") ? "espagnol" : ""}
        disabled={!isAvailable("espagnol")}
      >
        Espagnol {!isAvailable("espagnol") && "(complet)"}
      </option>
    </SelectOptionsInput>
  );
};

export const Lv3Options = ({
  value,
  handleSelect,
  formData,
  cursus,
  classe,
}) => {
  const info =
    cursus === "classique" || cursus === "bilingue" ? (
      <>
        <small>
          *LVC Arabe : la maitrise de la lecture et de l’écriture est requise.
        </small>
      </>
    ) : (
      ""
    );
  const isAvailable = (formData, lv3) => {
    //06/09/2022 : LVC espagnol indisponible pour les 5ème classique garçons matin
    // if (
    //   classe === "5eme" &&
    //   cursus === "classique" &&
    //   formData["gender"] === "boys" &&
    //   formData["slots"] === "morning" &&
    //   lv3 === "espagnol"
    // ) {
    //   return false;
    // }
    //15/08/2023 : LVC (arabe ou espagnol) indisponible pour les 1ere classique ST2S filles
    /*
    if (
      cursus === "classique" &&
      formData["branch"] === "Première ST2S" &&
      formData["gender"] === "girls"
    ) {
      return false;
    }*/
    return true;
  };
  return (
    <SelectOptionsInput
      name="lv3"
      value={value}
      info={info}
      required={false}
      handleSelect={handleSelect}
    >
      <option value="">Choisir la LVC* (Option payante)</option>
      {/* Cursus classique : si LVB arabe alors LVC espagnol et vice versa
      Pour les terminales classique 2022-2023 : lvb espagnol de base donc lvc arabe seulement
      Cursus double : LVC espagnol dans tous les cas (car de base LVB arabe) */}
      {formData["lv2"] === "" && (
        <>
          {cursus !== "double" && (
            <option
              value={isAvailable(formData, "arabe") ? "arabe" : ""}
              disabled={!isAvailable(formData, "arabe")}
            >
              Arabe (+ 351 € à l'année){" "}
              {!isAvailable(formData, "arabe") && "(non disponible)"}
            </option>
          )}
          {!(classe === "Terminale" && cursus === "classique") && (
            <option
              value={isAvailable(formData, "espagnol") ? "espagnol" : ""}
              disabled={!isAvailable(formData, "espagnol")}
            >
              Espagnol (+ 300 € à l'année){" "}
              {!isAvailable(formData, "espagnol") && "(non disponible)"}
            </option>
          )}
        </>
      )}
      {formData["lv2"] === "espagnol" && (
        <option
          value={isAvailable(formData, "arabe") ? "arabe" : ""}
          disabled={!isAvailable(formData, "arabe")}
        >
          Arabe (+ 351 € à l'année){" "}
          {!isAvailable(formData, "arabe") && "(non disponible)"}
        </option>
      )}
      {formData["lv2"] === "arabe" && (
        <option
          value={isAvailable(formData, "espagnol") ? "espagnol" : ""}
          disabled={!isAvailable(formData, "espagnol")}
        >
          Espagnol (+ 300 € à l'année){" "}
          {!isAvailable(formData, "espagnol") && "(non disponible)"}
        </option>
      )}
    </SelectOptionsInput>
  );
};

export const PaymentOptions = ({
  value,
  handleSelect,
  opt = { key: value },
}) => {
  const getText = (key) => {
    let text =
      key === "month"
        ? "mensuel"
        : key === "trimester"
        ? "trimestriel"
        : key === "year"
        ? "annuel"
        : key === "once" || key === "hour" || key === "one"
        ? "en 1x"
        : key === "twice"
        ? "en 2x"
        : key === "thrice"
        ? "en 3x"
        : null;

    return `Paiement ${text}`;
  };
  return (
    <SelectOptionsInput
      name="paymentMethod"
      value={value}
      handleSelect={handleSelect}
    >
      <option value="">Choisir un mode de paiement</option>
      {Object.keys(opt).map((value, v) => (
        <>
          {value !== "two" && (
            <option key={`paymentMethod-${v}`} value={value}>
              {getText(value)}
            </option>
          )}
        </>
      ))}
    </SelectOptionsInput>
  );
};

export const GeneralBrancheOptions = ({
  options,
  stock,
  formData,
  handleSelect,
}) => {
  //console.log("au moins ça", options, formData);
  //fonction:
  const optFilter = (optionNb, options) => {
    return optionNb === "firstOption"
      ? options
      : optionNb === "secondOption"
      ? options.filter((opt) => opt !== formData.firstOption)
      : options.filter(
          (opt) => opt !== formData.firstOption && opt !== formData.secondOption
        );
  };

  const stockByOption = (option, gender) => {
    //Exemple -> stock : { "Mathématiques" : { "girls" : bool, "boys" : bool }, ....}
    if (stock[option]) {
      return stock[option][gender];
    }
    return true;
  };

  //fonction:
  const getOptions = (optionName) => {
    let i =
      optionName === "firstOption" ? 1 : optionName === "secondOption" ? 2 : 3;
    return (
      <SelectOptionsInput
        name={optionName}
        value={formData[optionName]}
        handleSelect={handleSelect}
      >
        <option value="">Choisir la spécialité n°{i}</option>
        {optFilter(optionName, options).map((option, key) => (
          <option
            key={key}
            value={option}
            disabled={!stockByOption(option, formData["gender"])}
          >
            {option}{" "}
            {stockByOption(option, formData["gender"]) === false &&
              "(indisponible)"}
          </option>
        ))}
      </SelectOptionsInput>
    );
  };
  ///////////////////////////////return////////////////////////////////////////////////
  return (
    <Fragment>
      {getOptions("firstOption")}
      {formData["firstOption"] !== "" && getOptions("secondOption")}
      {formData["secondOption"] !== "" &&
        formData.branch !== "Terminale générale" &&
        getOptions("thirdOption")}
    </Fragment>
  );
};

export const CourseChoiceOptions = ({
  value,
  handleSelect,
  courses,
  category,
}) => {
  return (
    <SelectOptionsInput
      name="courseChoice"
      value={value}
      handleSelect={handleSelect}
    >
      <option value="">
        {category === "stage" ? "Choisir la formule" : "Choisir une matière"}
      </option>
      {courses.map((name, n) => {
        return (
          <option key={n} value={name}>
            {name}
          </option>
        );
      })}
    </SelectOptionsInput>
  );
};

export const StudentLevelOptions = ({
  formData, //à décocher quand il y aura une restriction de disponibilité
  productName, //à décocher quand il y aura une restriction de disponibilité
  value,
  handleSelect,
  levels,
}) => {
  const isAvailable = (level) => {
    //09/07/2023 : Stage d'été - Les classes de CP, CE1 et CM2, 6e garçons et 5e garçons sont indisponibles
    /*
    if (
      productName === "Stage d'été Juillet" &&
      (level === "CP" ||
        level === "CE1" ||
        level === "CM2" ||
        (level === "6eme" && formData["gender"] === "boys") ||
        (level === "5eme" && formData["gender"] === "boys"))
    ) {
      return false;
    }*/
    //21/01/2025 : Cours exclusifs : les maths ne sont plus disponibles pour collège et lycée
    levels = ["6e", "5e", "4e", "3e", "Seconde", "Première", "Terminale"];
    if (
      (productName === "Cours exclusifs - Lycée" ||
        productName === "Cours exclusifs - Primaire/collège") &&
      levels.includes(level) &&
      formData["courseChoice"] === "Mathématiques"
    ) {
      return false;
    }
    return true;
  };
  return (
    <SelectOptionsInput
      name="studentLevel"
      value={value}
      handleSelect={handleSelect}
    >
      <option value="">Indiquer le niveau</option>
      {levels.map((name, n) => (
        <option
          key={n}
          value={isAvailable(name) ? name : ""}
          disabled={!isAvailable(name)}
        >
          {name}
          {!isAvailable(name) ? " - complet" : ""}
        </option>
      ))}
    </SelectOptionsInput>
  );
};

export const NbHoursOptions = ({ value, handleSelect }) => {
  return (
    <SelectOptionsInput
      name="nbHours"
      value={value}
      handleSelect={handleSelect}
    >
      <option value="">Nombre d'heures / semaine</option>
      <option value="1">1 h</option>
      <option value="2">2 h</option>
      <option value="3">3 h</option>
      <option value="4">4 h</option>
      <option value="5">5 h</option>
    </SelectOptionsInput>
  );
};

export const DaysOption = ({ value, handleSelect, opt }) => {
  return (
    <SelectOptionsInput name="days" value={value} handleSelect={handleSelect}>
      <option value="">Choisir les jours</option>
      {opt.map((name, n) => (
        <option key={`day-${n}`} value={name}>
          {name}
        </option>
      ))}
    </SelectOptionsInput>
  );
};

export const SlotsOption = ({ value, handleSelect, opt }) => {
  return (
    <SelectOptionsInput name="slots" value={value} handleSelect={handleSelect}>
      <option value="">Choisir le créneau horaire</option>
      {opt.map((name, n) => (
        <option key={`slot-${n}`} value={name}>
          {name}
        </option>
      ))}
    </SelectOptionsInput>
  );
};

//disponibilités du client
export const Availability = ({ checkedBoxes, handleClick, isValid }) => {
  //composant enfant 1 : une seule checkbox avec son label
  const Checkbox = ({ name, value, label, handleClick }) => {
    let id = name + value;

    const config = () => {
      if (
        checkedBoxes[name] !== undefined &&
        checkedBoxes[name].includes(value)
      ) {
        return true;
      } else {
        return false;
      }
    };

    const [isChecked, setChecked] = useState(config);

    return (
      <FormGroup check>
        <Input
          type="checkbox"
          id={id}
          name={name}
          value={value}
          onChange={(e) => {
            handleClick(e);
            setChecked(config());
          }}
          checked={isChecked}
        />
        <Label check htmlFor={id}>
          {label}
        </Label>
      </FormGroup>
    );
  };

  const slots = [
    "8 h - 10 h",
    "10 h - 12 h",
    "12 h - 14 h",
    "14 h - 16 h",
    "16 h - 18 h",
    "18 h - 20 h",
  ];

  const afternoonSlots = ["14 h - 16 h", "16 h - 18 h", "18 h - 20 h"];

  //composant enfant 2 : un groupe de checkboxes pour les créneaux horaires
  const SlotsCheckboxes = ({ day, slots }) => {
    //retourne un tableau de checkboxes

    return slots.map((slot) => (
      <Checkbox
        key={day + slot}
        name={day}
        value={slot}
        label={slot}
        handleClick={handleClick}
      />
    ));
  };

  let feedbackStyle = isValid ? { display: "none" } : { display: "block" };

  //composant parent (Availability)

  return (
    <FormGroup>
      <p style={{ textAlign: "center" }}>
        J'indique à l'enseignant toutes les disponibilités de mon enfant
      </p>

      <Row style={{ justifyContent: "center" }}>
        <div style={{ marginRight: "1.5rem", marginBottom: "1rem" }}>
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: _Colors.blue,
            }}
          >
            Lundi
          </div>{" "}
          <SlotsCheckboxes day="lundi" slots={afternoonSlots} />
        </div>

        <div style={{ marginRight: "1.5rem", marginBottom: "1rem" }}>
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: _Colors.blue,
            }}
          >
            Mardi
          </div>{" "}
          <SlotsCheckboxes day="mardi" slots={afternoonSlots} />
        </div>

        <div style={{ marginRight: "1.5rem", marginBottom: "1rem" }}>
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: _Colors.blue,
            }}
          >
            Mercredi
          </div>{" "}
          <SlotsCheckboxes day="mercredi" slots={afternoonSlots} />
        </div>

        <div style={{ marginRight: "1.5rem", marginBottom: "1rem" }}>
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: _Colors.blue,
            }}
          >
            Jeudi
          </div>{" "}
          <SlotsCheckboxes day="jeudi" slots={afternoonSlots} />
        </div>

        <div style={{ marginRight: "1.5rem" }}>
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: _Colors.blue,
            }}
          >
            Vendredi
          </div>{" "}
          <SlotsCheckboxes day="vendredi" slots={slots} />
        </div>

        <div style={{ marginRight: "1.5rem" }}>
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: _Colors.blue,
            }}
          >
            Samedi
          </div>
          <SlotsCheckboxes day="samedi" slots={slots} />
        </div>

        <div style={{ marginRight: "1rem" }}>
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: _Colors.blue,
            }}
          >
            Dimanche
          </div>{" "}
          <SlotsCheckboxes day="dimanche" slots={afternoonSlots} />
        </div>
      </Row>
      <FormFeedback style={feedbackStyle}>
        Le nombre de créneaux est insuffisant au vu du nombre d'heures désiré
      </FormFeedback>
    </FormGroup>
  );
};
