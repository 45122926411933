import React from "react";
import {
  GenderOptions,
  GeneralBrancheOptions,
  Lv2Options,
  Lv3Options,
  PaymentOptions,
  SlotsOptions,
} from "../../GenericSelect";
import { SelectOptionsInput } from "../../GenericSelect/SelectOptionsInput";

const CompletCursusOptions = ({
  formData,
  handleSelect,
  config,
  stock,
  cursus,
  classe,
}) => {
  //console.log(formData, cursus, classe);
  const { genderOpt, slotsOpt, branches, options, lv2Opt, lv3Opt, paymentOpt } =
    config;

  const stockByGender = (genderValue = "") => {
    //cas 1 : stock : { girls : bool, boys : bool }
    if (typeof stock[genderValue] === "boolean") {
      return stock[genderValue];
    }
    //cas 2 : stock : { girls : { morning : bool, afternoon : bool }, .....}
    else {
      return Boolean(
        stock[genderValue]["morning"] || stock[genderValue]["afternoon"]
      );
    }
  };

  /*const stockBySlots = (slotValue = "") => {
    if (genderOpt) {
      return stock[formData["gender"]][slotValue];
    } else {
      return stock[slotValue];
    }
  };*/

  const stockByBranch = (branch, gender) => {
    //Exemple -> stock : { "Première generale" : { "girls" : bool, "boys" : bool }, ....}
    if (stock[branch] && classe !== "Maternelle") {
      return stock[branch][gender];
    }
    if (classe === "Maternelle") {
      return stock[branch];
    }

    return true;
  };

  return (
    <>
      {genderOpt && (
        <GenderOptions
          value={formData["gender"]}
          handleSelect={handleSelect}
          isOpen={stockByGender}
        />
      )}

      {branches !== undefined && (
        <SelectOptionsInput
          handleSelect={handleSelect}
          name="branch"
          value={formData["branch"]}
          required
        >
          <option value="">
            {classe === "Maternelle"
              ? "Choisir la classe"
              : "Choisir la filière**"}
          </option>
          {branches.map((branch, key) => (
            <option
              key={key}
              value={branch}
              disabled={!stockByBranch(branch, formData["gender"])}
            >
              {branch}{" "}
              {formData["gender"] === "" &&
                classe !== "Maternelle" &&
                " - choisir d'abord la classe"}
              {stockByBranch(branch, formData["gender"]) === false &&
                "(complet)"}
            </option>
          ))}
        </SelectOptionsInput>
      )}
      {slotsOpt && (
        <SlotsOptions
          value={formData["slots"]}
          handleSelect={handleSelect}
          stock={stock}
          formData={formData}
          handleByGender={genderOpt}
        />
      )}
      {options !== undefined && (
        <GeneralBrancheOptions
          options={options}
          stock={stock}
          formData={formData}
          handleSelect={handleSelect}
        />
      )}
      {lv2Opt && (
        <Lv2Options
          stock={stock}
          handleSelect={handleSelect}
          formData={formData}
        />
      )}
      {lv3Opt && (
        <Lv3Options
          value={formData["lv3"]}
          handleSelect={handleSelect}
          formData={formData}
          cursus={cursus}
          classe={classe}
        />
      )}
      {paymentOpt && (
        <PaymentOptions
          opt={config.paymentOpt}
          value={formData["paymentMethod"]}
          handleSelect={handleSelect}
        />
      )}
    </>
  );
};

export default CompletCursusOptions;
