import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { Products } from "../components/WebSiteApp/Products/Products";
import tvaList from "../components/WebSiteApp/Subscription/components/tvaList";
import Cookies from "js-cookie";

//url

export const routesState = atom({
  key: "routesState",
  default: [
    "/",
    "/admin",
    "/admin/dashboard",
    "/cursus-classique/maternelle",
    "/cursus-classique/primaire",
    "/cursus-classique/primaire/cp",
    "/cursus-classique/primaire/ce1",
    "/cursus-classique/primaire/ce2",
    "/cursus-classique/primaire/cm1",
    "/cursus-classique/primaire/cm2",
    "/cursus-classique/college",
    "/cursus-classique/college/sixieme",
    "/cursus-classique/college/cinquieme",
    "/cursus-classique/college/quatrieme",
    "/cursus-classique/college/troisieme",
    "/cursus-classique/lycee",
    "/cursus-classique/lycee/seconde",
    "/cursus-classique/lycee/premiere",
    "/cursus-classique/lycee/premiere/generale",
    "/cursus-classique/lycee/premiere/stmg",
    "/cursus-classique/lycee/premiere/st2s",
    "/cursus-classique/lycee/premiere/tronc-commun",
    "/cursus-classique/lycee/terminale",
    "/cursus-classique/lycee/terminale/generale",
    "/cursus-classique/lycee/terminale/stmg",
    "/cursus-classique/lycee/terminale/st2s",
    "/cursus-classique/lycee/terminale/tronc-commun",
    "/double-cursus",
    "/double-cursus/primaire",
    "/double-cursus/primaire/cp",
    "/double-cursus/primaire/ce1",
    "/double-cursus/primaire/ce2",
    "/double-cursus/primaire/cm1",
    "/double-cursus/primaire/cm2",
    "/double-cursus/college",
    "/double-cursus/college/sixieme",
    "/double-cursus/college/cinquieme",
    "/double-cursus/college/quatrieme",
    "/double-cursus/college/troisieme",
    "/double-cursus/lycee",
    "/double-cursus/lycee/seconde",
    "/double-cursus/lycee/premiere/generale",
    "/double-cursus/lycee/premiere/stmg",
    "/double-cursus/lycee/premiere/st2s",
    "/double-cursus/lycee/terminale/generale",
    "/double-cursus/lycee/terminale/stmg",
    "/double-cursus/lycee/terminale/st2s",
    "/cursus-bilingue/primaire",
    "/cursus-bilingue/college",
    "/cursus-bilingue/lycee",
    "/cursus-belge/primaire",
    "/confirmation-envoi",
    "/cursus-pec",
    "/ateliers-recreatifs",
    "/cursus-a-la-carte",
    "/la-madrassa-authentique",
    "/offres",
    "/tarifs-cursus-classique",
    "/tarifs-double-cursus",
    "/tarifs-cursus-bilingue",
    "/tarifs-cursus-belge",
    "/tarifs-cursus-a-la-carte",
    "/tarifs-ateliers-recreatifs",
    "/tarifs-pec",
    "/actualites",
    "/calendrier-scolaire",
    "/temoignages",
    "/faq",
    "/qui-sommes-nous",
    "/recrutement",
    "/contact",
    //"/paiement",
    "/checkout",
    //"/fiches",
    "/documents/reglement-financier-2025-2026.pdf",
    "/documents/Autorisations-parentales.pdf",
    "/documents/Fiche-sanitaire.pdf",
    "/documents/CGV-US-24-25.pdf",
    "/documents/Politique-de-confidentialité-21-22.pdf",
    "/documents/volume-horaire-primaire-classique.pdf",
    "/documents/volume-horaire-college-classique.pdf",
    "/documents/niveaux_cursus_bilingue.pdf",
    "/documents/niveaux_CECRL.pdf",
    "/documents/taux_TVA_Union_Européenne.pdf",
    "/documents/reglement-interieur-2024-2025.pdf",
    "/documents/presentation-specialites.pdf",
    "/verification-email",
    "/forgot-password",
    "/register",
    "/mon-espace",
    "/404",
  ],
});

//production ou développement
export const isProduction = process.env.NODE_ENV === "production";
export const isDevelopment = process.env.NODE_ENV === "development";

//année actuelle et années scolaires actuelle et précédente ///////////////////////////////////////////////////////////////////////////

function calculSchoolYears() {
  let currentSchoolYear;
  let previousSchoolYear;
  const date = new Date();
  const thisYear = date.getFullYear();
  const FebruaryFirstOfThisYear = new Date(thisYear, 1, 1, 0, 0, 0); //année, mois(0-indexé), jour, heure, min, sec
  if (date >= FebruaryFirstOfThisYear) {
    currentSchoolYear = `${thisYear}-${thisYear + 1}`;
    previousSchoolYear = `${thisYear - 1}-${thisYear}`;
  } else {
    currentSchoolYear = `${thisYear - 1}-${thisYear}`;
    previousSchoolYear = `${thisYear - 2}-${thisYear - 1}`;
  }
  return {
    currentYear: thisYear,
    currentSchoolYear: currentSchoolYear,
    previousSchoolYear: previousSchoolYear,
  };
}
const schoolYears = calculSchoolYears();
export const currentYearState = atom({
  key: "currentYearState",
  default: schoolYears.currentYear,
});

export const currentSchoolYearState = atom({
  key: "currentSchoolYearState",
  default: schoolYears.currentSchoolYear,
});

export const previousSchoolYearState = atom({
  key: "previousSchoolYearState",
  default: schoolYears.previousSchoolYear,
});

// PRODUITS  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//tous les produits
export const productsState = atom({
  key: "productsState",
  default: Products,
});

//light : produits dont le formulaire d'inscription est minimal
export const lightProductsSelector = selector({
  key: "lightProductsSelector",
  get: ({ get }) => {
    const products = get(productsState);
    const lightProductsName = [
      "Cours exclusifs - Primaire/collège",
      "Cours exclusifs - Lycée",
      "Cours exclusifs - Université",
      "Cours exclusifs - Coran",
      "Cours exclusifs - Sciences religieuses",
      "Stage essentiel",
      "Stage bénéfique",
      "Stage de langues",
      "Ateliers de langues",
      "Ateliers scientifiques",
      "Ateliers d'éloquence",
    ];
    //products est un array d'objets
    let array = products.filter((obj) => {
      return lightProductsName.includes(obj["name"]);
    });
    return array;
  },
});

//full : produits dont le formulaire d'inscription contient tous les champs possibles (complet)
export const fullProductsSelector = selector({
  key: "fullProductsSelector",
  get: ({ get }) => {
    const products = get(productsState);
    const fullProductsCursus = ["classique", "double", "bilingue", "belge"];
    //products est un array d'objets
    let array = products.filter((obj) => {
      return fullProductsCursus.includes(obj["cursus"]);
    });
    return array;
  },
});

//half : produits dont le formulaire d'inscription est semi-complet
export const halfProductsSelector = selector({
  key: "halfProductsSelector",
  get: ({ get }) => {
    const products = get(productsState);
    const fullProducts = get(fullProductsSelector);
    const lightProducts = get(lightProductsSelector);
    let array = products.filter((obj) => {
      return !fullProducts.includes(obj) && !lightProducts.includes(obj);
    });
    return array;
  },
});

//type du produit : light, full ou half ?
export const productTypeSelector = selector({
  key: "productTypeSelector",
  get:
    (product) =>
    ({ get }) => {
      const fullProducts = get(fullProductsSelector);
      const lightProducts = get(lightProductsSelector);
      if (lightProducts.includes(product)) {
        return "lightProduct";
      } else if (fullProducts.includes(product)) {
        return "fullProduct";
      } else {
        return "halfProduct";
      }
    },
});

//produits triés selon le cursus en paramètre (pour les cartes qui apparaissent dans la page tarif)
export const productsSelector = selectorFamily({
  key: "productsSelector",
  get:
    (cursus) =>
    ({ get }) =>
      get(productsState).filter((product) =>
        cursus === "classique"
          ? product.cursus === cursus || product.name === "Tronc Commun" //cursus classique + TC
          : product.cursus === cursus
      ),
});

//produits triés selon la section en paramètre (pour les cartes qui apparaissent dans les pages produits)
export const productsSelectorBySection = selectorFamily({
  key: "productsSelectorBySection",
  get:
    (section) =>
    ({ get }) =>
      get(productsState).filter((product) => product.section === section),
});

// PANIER /////////////////////////////////////////////////////////////////////////////////////////////////////////////

//gestion de la TVA : 2 variables
export const countryState = atom({
  key: "countryState",
  default: "France",
});

export const tvaRateSelector = selector({
  key: "tvaRateSelector",
  get: ({ get }) =>
    tvaList[get(countryState)] ? tvaList[get(countryState)] : 0,
});

export const cartProductsState = atom({
  //il s'agit d'un tableau d'objets
  key: "cartProductsState",
  default: localStorage.productsCart
    ? JSON.parse(localStorage.productsCart)
    : [],
});

export const cartProductsSelector = selector({
  key: "cartProducts",
  get: ({ get }) => get(cartProductsState),
  set: ({ set }, newProduct) =>
    set(cartProductsState, (prevState) => [...prevState, newProduct]),
});

export const cartProductsNumberSelector = selector({
  key: "cartProductsNumber",
  get: ({ get }) => {
    let state = get(cartProductsState);

    return state.length;
  },
});

//calcule le total du panier
export const totalProductsPriceSelector = selector({
  key: "cartProductsTotalPriceSelector",
  get: ({ get }) => {
    let productsCart = get(cartProductsState);

    if (productsCart.length !== 0) {
      let totalProductsPrice = 0;

      productsCart.forEach((product) => {
        //product.price = une portion du versement adpatée au cursus et au mode de paiement
        //ex : cursus classique - classe 5eme - paiement par mois : product.price = 144
        //ex : cursus classique - classe 5eme - paiement par trimestre : product.price = 456
        //ainsi, totalPrice calcule la somme des portions du produit
        let totalPrice =
          product.formData.paymentMethod === "month"
            ? parseFloat(product.price) * 10
            : product.formData.paymentMethod === "trimester"
            ? parseFloat(product.price) * 3
            : product.formData.paymentMethod === "year"
            ? parseFloat(product.price)
            : product.formData.paymentMethod === "once"
            ? parseFloat(product.price)
            : product.formData.paymentMethod === "twice"
            ? parseFloat(product.price) * 2
            : product.formData.paymentMethod === "thrice"
            ? parseFloat(product.price) * 3
            : product.name.includes("Cours exclusifs")
            ? parseFloat(product.price) *
              parseFloat(product.formData.nbHours) *
              4
            : parseFloat(product.price);

        //ajout du coût de l'option lv3 si le produit en est doté
        if (product.formData.lv3 !== "") {
          let optionCost = product.formData.lv3 === "arabe" ? 351 : 300;
          totalPrice += optionCost;
        }

        totalProductsPrice = totalProductsPrice + totalPrice;
      });

      return totalProductsPrice;
    }
    return 0;
  },
});

export const dayliCostsSelector = selector({
  key: "dayliCosts",
  get: ({ get }) => {
    let productsCart = get(cartProductsState);
    let promotion = 0;
    let accounts = 0;
    let registrationFees = 0;
    let scolarityCosts = 0;
    let dailyTotal = 0;

    if (productsCart.length !== 0) {
      productsCart.forEach((product) => {
        //console.log(product);

        if (
          product.cursus === "classique" ||
          product.cursus === "double" ||
          (product.cursus === "carte" && product.category === "pole")
        ) {
          if (product.cursus !== "carte") {
            accounts += 100;
          }
          registrationFees += 39;
        }

        //calcul des frais de scolarité
        if (
          product.cursus === "carte" ||
          product.cursus === "pec" ||
          product.cursus === "atelier"
        ) {
          if (product.name.includes("Cours exclusifs")) {
            scolarityCosts =
              scolarityCosts +
              parseFloat(product.price) *
                parseFloat(product.formData.nbHours) *
                4;
          } else {
            scolarityCosts = scolarityCosts + parseFloat(product.price);
          }
        }

        // if(product.name === 'Pôle Arabe' || ['classique','double'].includes(product.cursus)){
        //     promotion += get(promotionCodesState) !== null ? 35 : 0
        // }
      });
    }

    //calcul de la promotion
    promotion = get(promotionState) !== null ? registrationFees : promotion;

    dailyTotal = scolarityCosts + accounts + registrationFees - promotion;

    return {
      accounts,
      registrationFees,
      scolarityCosts,
      promotion,
      dailyTotal,
    };
  },
});

export const deleteProductCartSelector = selectorFamily({
  key: "deleteProductCartSelector",
  get:
    () =>
    ({ get }) =>
      get(cartProductsState),
  set:
    (productId) =>
    ({ set, get }) => {
      let prevState = get(cartProductsState);
      let newState =
        Array.isArray(prevState) &&
        prevState.filter((product) => product.id !== productId);
      newState !== undefined && set(cartProductsState, newState);
    },
});

export const deleteAlertState = atom({
  key: "deleteAlertState",
  default: [false, null],
});

export const deleteAlertSelector = selector({
  key: "deleteAlertSelector",
  get: ({ get }) => get(deleteAlertState),
  set: ({ set }, newState) => set(deleteAlertState, newState),
});

// promotion code state management

export const promotionalProductsState = atom({
  key: "promotionalProductsState",
  default: [],
});

export const promotionalProductsSelector = selector({
  key: "promotionalProductsSelector",
  get: ({ get }) => get(promotionalProductsState),
  set: ({ set }, productsArray) => set(promotionalProductsState, productsArray),
});

export const promotionState = atom({
  key: "promotionState",
  default: null,
});

export const promotionSelector = selector({
  key: "promotionSelector",
  get: ({ get }) => get(promotionState),
  set: ({ set }, promotion) => set(promotionState, promotion),
});

//inscriptions purchase state management

/*export const inscriptionPurchaseState = atom({
  key: "inscriptionPurchaseState",
  default: null,
});

export const inscriptionPurchaseSelector = selector({
  key: "inscriptionPurchaseSelector",
  get: ({ get }) => get(inscriptionPurchaseState),
  set: ({ set }, purchase) => {
    set(inscriptionPurchaseState, purchase);
  },
});*/

// subscription form state management

export const formParentState = atomFamily({
  key: "formParentState",
  default: {
    parentStatut: "",
    otherParentStatut: "",
    parentLastname: "",
    parentFirstname: "",
    parentNationality: "",
    parentProfession: "",
    parentAddress: "",
    parentCity: "",
    parentCountry: "",
    parentEmail: "",
    parentPhone: "",
    parentWhatsapp: "",
    parentFacebook: "",
  },
});

export const formChildState = atomFamily({
  key: "formChildState",
  default: {},
});

/*export const formDataSelector = selector({
  key: "formDataSelector",
  get: ({ get }) => {
    let dataStore = {
      childs: [],
      parents: [],
    };
  },
});*/

export const formDataState = atom({
  key: "formDataState",
  default: { childs: [], parents: [] },
});

export const childDataSelector = selectorFamily({
  key: "childDataSelector",
  get:
    (id) =>
    ({ get }) => {
      return get(formDataState).childs[id];
    },
  set:
    (id) =>
    ({ set, get }, newValue) => {
      let state = get(formDataState).childs;
      set({
        ...state,
        [id]: { ...state[id], [newValue.name]: newValue.value },
      });
    },
});

// toggle modal of products cart

export const openProducstCartModal = atom({
  key: "openProducstCartModal",
  default: false,
});

export const openProducstCartModalSelector = selector({
  key: "openProducstCartModalSelector",
  get: ({ get }) => get(openProducstCartModal),
  set: ({ set }, newValue) => {
    set(openProducstCartModal, newValue);
  },
});

// toggle modal of products cart

export const openReturnToFormModal = atom({
  key: "openReturnToFormModal",
  default: false,
});

export const openReturnToFormSelector = selector({
  key: "openReturnToFormSelector",
  get: ({ get }) => get(openReturnToFormModal),
  set: ({ set }, newValue) => {
    set(openReturnToFormModal, newValue);
  },
});
