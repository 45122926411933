import { createUseStyles } from "react-jss";
import { _Colors } from "../../../Jss-styles/_variables";

export const useStyles = createUseStyles({
  PageCursus: {
    fontFamily:
      "'Segoe UI', Roboto, 'San Francisco', 'Helvetica Neue', Arial, sans-serif",
    fontSize: "20px",
    lineHeight: "40px",
  },
  Banniere: {
    backgroundColor: _Colors.violet,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "80px",
    height: "200px",
  },
  PageWrapper: {
    maxWidth: "1497px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  H1: {
    textAlign: "center",
    fontFamily: "Yusei Magic",
    fontSize: "35px",
    lineHeight: "85px",
    color: "#4F5AB1", //bleu foncé
    marginBottom: "80px",
  },
  H2: {
    fontFamily: "Yusei Magic",
    fontSize: "24px",
    lineHeight: "30px",
    color: "#4F5AB1", //bleu foncé
    marginBottom: "20px",
  },

  H2Tarifs: {
    textAlign: "center",
    fontFamily: "Yusei Magic",
    fontSize: "28px",
    lineHeight: "30px",
    color: _Colors.violet,
    marginBottom: "35px",
  },
  H2Promo: {
    textAlign: "center",
    fontFamily: "Yusei Magic",
    fontSize: "20px",
    lineHeight: "30px",
    color: _Colors.violet,
  },
  H2Offres: {
    textAlign: "center",
    fontFamily: "Yusei Magic",
    fontSize: "20px",
    lineHeight: "30px",
    color: "black",
    marginBottom: "45px",
    marginTop: "70px",
  },
  H2Garanties: {
    fontFamily: "Yusei Magic",
    fontSize: "22px",
    lineHeight: "30px",
    marginBottom: "28px",
  },
  H3: {
    extend: "H2Garanties",
  },
  VideoEtPromesse: {
    display: "flex",
    columnGap: "90px",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "72px",
  },
  Promesse: {
    display: "flex",
    flexDirection: "column",
    rowGap: "45px",
    alignItems: "center",
  },
  TxtPromesse: {
    width: "571px",
    fontSize: "24px",
    lineHeight: "50px",
    textAlign: "center",
  },
  BtnPromesse: {
    paddingInline: "50px",
    paddingBlock: "5px",
    color: "white",
    backgroundColor: _Colors.violet,
    border: `1px solid ${_Colors.violet}`,
    transition: "all 0.3s ease",
    "&:hover": {
      transform: "scale(1.1)",
    },
    "&:active": {
      backgroundColor: "white",
      borderColor: _Colors.violet,
      color: _Colors.violet,
    },
  },
  BtnPromesseClassique: {
    extend: "BtnPromesse",
    backgroundColor: _Colors.blue,
    border: `1px solid ${_Colors.blue}`,
    "&:active": {
      borderColor: _Colors.blue,
      color: _Colors.blue,
    },
  },
  BtnPromesseDouble: {
    extend: "BtnPromesse",
    backgroundColor: _Colors.purple,
    border: `1px solid ${_Colors.purple}`,
    "&:active": {
      borderColor: _Colors.purple,
      color: _Colors.purple,
    },
  },
  BtnPromesseBelge: {
    extend: "BtnPromesse",
    backgroundColor: _Colors.pink,
    border: `1px solid ${_Colors.pink}`,
    "&:active": {
      borderColor: _Colors.pink,
      color: _Colors.pink,
    },
  },
  Accroche: {
    textAlign: "center",
    fontFamily: "Yusei Magic",
    fontSize: "28px",
    lineHeight: "50px",
    color: "black",
    backgroundColor: `${_Colors.violet2}50`,
    paddingBlock: "20px",
    marginBottom: "70px",
    "& strong": {
      fontWeight: "500",
    },
  },
  InformationsCursus: {
    display: "flex",
    columnGap: "85px",
    "& a": {
      color: "#4F5AB1", //bleu foncé
    },
  },
  ColonnesGauchesContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: "450px",
    maxWidth: "450px",
  },
  ColonneGauche: {
    backgroundColor: _Colors.violet2,
    height: "max-content",
    textAlign: "center",
    padding: "20px",
  },
  ColonneGauche2: {
    backgroundColor: "white",
    paddingInline: "0",
    marginTop: "2em",
    "& img": {
      maxWidth: "100%",
      marginBlock: "2em",
    },
  },
  EmploiDuTemps: {
    "& small": {
      fontSize: "1rem",
      lineHeight: "1.8rem",
    },
  },
  Indications: {
    marginBottom: "20px",
  },
  LevelsBar: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "flex",
    columnGap: "10px",
    justifyContent: "center",
    flexWrap: "wrap",
    rowGap: "10px",
  },
  LevelBtn: {
    width: "66px",
    minWidth: "fit-content",
    fontFamily: "Yusei Magic",
    padding: "5px",
    backgroundColor: "white",
    border: "2px dotted #707070",
    borderRadius: "10px",
    textAlign: "center",
    cursor: "pointer",
    transition: "all 200ms ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
      cursor: "pointer",
    },
  },
  Edt: {
    marginBottom: "2em",
    "& p": {
      marginTop: "2rem",
      textAlign: "center",
    },
  },
  Supports: {
    display: "flex",
    flexDirection: "column",
  },
  FlexEdtSupports: {},
  BtnVolumeHoraire: {
    display: "block",
    marginTop: "20px",
    padding: "5px 10px",
    backgroundColor: "greenyellow",
    borderRadius: "5px",
    marginInline: "auto",
    transition: "all 100ms ease-in",
    "&:hover": {
      backgroundColor: "palegreen",
      textDecoration: "none",
    },
  },
  BtnSupports: {
    width: "90%",
    marginInline: "auto",
    paddingBlock: "12px",
    color: "white",
    borderRadius: "5px",
    marginTop: "26px",
    transition: "all 200ms ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
      cursor: "pointer",
    },
    marginBottom: "0",
    textAlign: "center",
    fontFamily: "'Yusei Magic'",
    fontSize: "26px",
    lineHeight: "40px",
  },
  Indication: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ImgEdt: {
    width: "100%",
    cursor: "pointer",
    transition: "all 0.3s ease",
    "&:hover": {
      transform: "scale(0.95)",
    },
    "&:active": {
      transform: "scale(0.9)",
    },
  },
  ContenuPedagogique: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& i": {
      color: "#4d59b0",
      cursor: "pointer",
      fontSize: "2rem",
      "&:hover": {
        color: "#aa39b6",
      },
    },
  },
  ImgSommaire: {
    extend: "ImgEdt",
    width: "calc(100% - 40px)",
  },
  ColonneDroite: {
    maxWidth: "100%",
  },
  PetiteAccroche: {
    textAlign: "center",
    fontFamily: "Yusei Magic",
    fontSize: "22px",
    lineHeight: "30px",
    marginBlock: "60px",
    "& strong": {
      fontWeight: "500",
    },
  },
  Illustration: {
    marginBottom: "60px",
    display: "block",
    margin: "auto",
  },
  Liste: {
    listStyleType: "none",
    padding: "0",
    "& li": {
      fontFamily: "Yusei Magic",
      fontSize: "20px",
      lineHeight: "30px",
      color: "black",
      display: "flex",
      columnGap: "12px",
      marginBottom: "20px",
    },
  },
  Frise: {
    marginBlock: "40px",
  },
  TogglePays: {
    display: "flex",
    columnGap: "10px",
    marginTop: "10px",
    "& div": {
      extend: "LevelBtn",
      padding: "0",
      width: "100px",
    },
  },
  ModulesAnglais: {
    marginBottom: "70px",
  },
  NiveauxPrimaireBilingue: {
    maxWidth: "100%",
    margin: "0px 0px 50px 0px",
  },
  CompetencesLangagieres: { margin: "35px 0px 60px 0px", maxWidth: "400px" },
  ApresParagraphe: {
    marginBottom: "60px",
  },

  BtnZoom: {
    paddingInline: "55px",
    color: "#1362FE", //bleu zoom
    backgroundColor: "white",
    border: "1px solid #1362FE",
    borderRadius: "5px",
    transition: "all 0.3s ease",
    "&:active": {
      backgroundColor: "#1362FE",
      borderColor: "white",
      color: "white",
    },
  },
  PreuveSociale: {
    backgroundColor: _Colors.violet3,
    padding: "60px 70px 40px 70px",
    display: "flex",
    alignItems: "center",
    columnGap: "40px",
    marginTop: "115px",
    marginBottom: "60px",
    "& h2": {
      textAlign: "center",
    },
    "& blockquote": {
      fontSize: "1.1rem",
      lineHeight: "1.8rem",
      backgroundColor: "white",
      padding: "2em",
      border: `3px dotted ${_Colors.purple}`,
      borderRadius: "5px",
      "& footer": {
        marginTop: "1em",
      },
    },
  },
  TemoignagesContainer: {
    display: "flex",
    columnGap: "2em",
    position: "relative",
    "& > i": {
      position: "absolute",
      fontSize: "2rem",
      color: `${_Colors.purple}`,
      cursor: "pointer",
      "&:hover": {
        color: "blue",
      },
      "&:active": {
        color: "white",
      },
    },
  },
  DivTarifs: {
    fontFamily: "Yusei Magic",
    textAlign: "center",
    fontSize: "22px",
    marginBottom: "40px",
    "& div": {
      marginBottom: "20px",
    },
    "& span": {
      fontSize: "28px",
    },
  },
  FlechePromo: {
    display: "block",
    marginInline: "auto",
    marginBottom: "20px",
    "& rect, path": {
      fill: _Colors.violet2, // Couleur de remplissage initiale
      animation: `$changeFillColorAnimation 1s infinite`,
    },
  },
  FlechePromoClassique: {
    extend: "FlechePromo",
    "& rect, path": {
      fill: _Colors.blue2,
      animation: `$changeFillColorAnimationClassique 1s infinite`,
    },
  },
  FlechePromoDouble: {
    extend: "FlechePromo",
    "& rect, path": {
      fill: _Colors.purple2,
      animation: `$changeFillColorAnimationDouble 1s infinite`,
    },
  },

  FlechePromoBelge: {
    extend: "FlechePromo",
    "& rect, path": {
      fill: _Colors.rose2,
      animation: `$changeFillColorAnimationBelge 1s infinite`,
    },
  },
  DivPromo: {
    marginBlock: "25px",
    border: `2px solid ${_Colors.violet}`,
    textAlign: "center",
    marginInline: "auto",
    fontFamily: "Yusei Magic",
    fontSize: "18px",
    lineHeight: "30px",
    padding: "18px",
    width: "796px",
    "& span": {
      color: "red",
    },
    animation: `$changeBorderColorAnimation 1s infinite`,
  },
  DivPromoClassique: {
    extend: "DivPromo",
    borderColor: _Colors.blue,
    animation: `$changeBorderColorAnimationClassique 1s infinite`,
  },
  DivPromoDouble: {
    extend: "DivPromo",
    borderColor: _Colors.purple,
    animation: `$changeBorderColorAnimationDouble 1s infinite`,
  },
  DivPromoBelge: {
    extend: "DivPromo",
    borderColor: _Colors.pink,
    animation: `$changeBorderColorAnimationBelge 1s infinite`,
  },
  BtnPromo: {
    display: "block",
    paddingBlock: "5px",
    marginInline: "auto",
    paddingInline: "50px",
    color: "white",
    backgroundColor: _Colors.violet,
    border: `1px solid ${_Colors.violet}`,
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "white",
      borderColor: _Colors.violet,
      color: _Colors.violet,
    },
    "&:active": {
      backgroundColor: _Colors.violet,
      borderColor: "white",
      color: "white",
    },
  },
  BtnPromoClassique: {
    extend: "BtnPromo",

    backgroundColor: _Colors.blue,
    borderColor: _Colors.blue,
    "&:hover": {
      borderColor: _Colors.blue,
      color: _Colors.blue,
    },
    "&:active": {
      backgroundColor: _Colors.blue,
    },
  },
  BtnPromoDouble: {
    extend: "BtnPromo",
    backgroundColor: _Colors.purple,
    borderColor: _Colors.purple,
    "&:hover": {
      borderColor: _Colors.purple,
      color: _Colors.purple,
    },
    "&:active": {
      backgroundColor: _Colors.purple,
    },
  },
  BtnPromoBelge: {
    extend: "BtnPromo",
    backgroundColor: _Colors.pink,
    borderColor: _Colors.pink,
    "&:hover": {
      borderColor: _Colors.pink,
      color: _Colors.pink,
    },
    "&:active": {
      backgroundColor: _Colors.pink,
    },
  },
  AutresOffres: {
    display: "flex",
    columnGap: "30px",
    justifyContent: "center",
    "& img": {
      transition: "0.3s ease",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.05)",
      },
    },
  },
  Garanties: {
    textAlign: "center",
    backgroundColor: "#095EAB",
    marginTop: "120px",
    paddingTop: "35px",
    paddingBottom: "25px",
    color: "white",
    "& > div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      columnGap: "100px",
      marginTop: "28px",
    },
    "& p": {
      maxWidth: "412px",
      fontSize: "22px",
      lineHeight: "30px",
    },
  },
  ModalEdt: {
    position: "fixed",
    top: "0",
    left: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.6)",
    zIndex: "999",
    "& img": {
      height: "70%",
      position: "relative",
      cursor: "pointer",
      animation: "$appearAnimation 0.8s",
    },
  },
  ModalForm: {
    position: "fixed",
    top: "0",
    left: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.6)",
    zIndex: "999",
    "& form": {
      position: "relative",
      backgroundColor: "white",
      maxHeight: "70%",
      padding: "2em",
      borderRadius: "0.2em",
      animation: "$appearAnimation 0.8s",
      "& i": {
        position: "absolute",
        top: "20px",
        right: "20px",
        cursor: "pointer",
      },
      "& .form-check": {
        display: "flex",
        alignItems: "center",
      },
      "& button": {
        display: "block",
        marginInline: "auto",
        marginTop: "1em",
        backgroundColor: _Colors.darkGreen,
      },
    },
  },

  "@keyframes changeBorderColorAnimation": {
    "0%": { borderColor: _Colors.violet },
    "100%": { borderColor: _Colors.violet2 },
  },
  "@keyframes changeBorderColorAnimationClassique": {
    "0%": { borderColor: _Colors.blue },
    "100%": { borderColor: _Colors.blue2 },
  },
  "@keyframes changeBorderColorAnimationDouble": {
    "0%": { borderColor: _Colors.purple },
    "100%": { borderColor: _Colors.purple2 },
  },
  "@keyframes changeBorderColorAnimationBelge": {
    "0%": { borderColor: _Colors.pink },
    "100%": { borderColor: _Colors.pink2 },
  },
  "@keyframes changeFillColorAnimation": {
    "0%": { fill: _Colors.violet },
    "100%": { fill: _Colors.violet2 },
  },
  "@keyframes changeFillColorAnimationClassique": {
    "0%": { fill: _Colors.blue },
    "100%": { fill: _Colors.blue2 },
  },
  "@keyframes changeFillColorAnimationDouble": {
    "0%": { fill: _Colors.purple },
    "100%": { fill: _Colors.purple2 },
  },
  "@keyframes changeFillColorAnimationBelge": {
    "0%": { fill: _Colors.pink },
    "100%": { fill: _Colors.pink2 },
  },
  "@keyframes appearAnimation": {
    from: {
      opacity: 0,
      transform: "scale(0)",
    },
    to: {
      opacity: 1,
      transform: "scale(1)",
    },
  },

  ////////////////////////////////////////////////////////////////////////////////////MQ/////////////////////////////////////////////////////////////
  "@media only screen and (max-width: 1600px)": {
    PageWrapper: {
      paddingInline: "5%",
    },
  },
  /* TABLETTES */
  "@media only screen and (max-width: 1365px)": {
    /*rem : polices - em: autres tailles*/

    Banniere: {
      marginBottom: "3.5em",
      "& img": {
        objectFit: "cover",
        overflowX: "hidden",
      },
    },
    H1: {
      lineHeight: "3.4rem",
      "& > div:first-child": {
        marginBottom: "0.5em",
      },
      marginBottom: "2em",
    },
    VideoEtPromesse: {
      display: "flex",
      flexDirection: "column",
      rowGap: "2em",
      justifyContent: "initial",
      marginBottom: "2.5em",
    },
    Promesse: {
      rowGap: "2em",
      maxWidth: "100%",
    },
    Accroche: {
      fontSize: "1.5rem",
      padding: "0.5em",
      marginBottom: "2em",
    },
    InformationsCursus: {
      flexDirection: "column",
      alignItems: "center",
      rowGap: "2.25em",
    },
    ColonnesGauchesContainer: {
      minWidth: "unset",
      maxWidth: "unset",
      width: "70%",
    },
    ColonneGauche: {
      padding: "1em",
    },

    EmploiDuTemps: {
      "& small": {
        fontSize: "0.8rem",
      },
    },
    Indications: {
      marginBottom: "1.5em",
    },

    PetiteAccroche: {
      lineHeight: "3rem",
      marginBlock: "2em",
    },

    Liste: {
      "& li": {
        fontSize: "1.25rem",
        lineHeight: "2rem",
      },
    },
    Frise: {
      marginBlock: "3rem",
    },
    ModulesAnglais: {
      marginBottom: "2.5em",
    },
    NiveauxPrimaireBilingue: {
      marginTop: "2em",

      display: "block",
      marginInline: "auto",
      width: "60%",
    },
    CompetencesLangagieres: {
      display: "block",
      marginInline: "auto",
      marginBottom: "4rem",
    },

    BtnZoom: {
      paddingBlock: "0.5em",
    },
    PreuveSociale: {
      padding: "1.5em 1.5em 0.5em 1.5em",
      flexDirection: "column",
      alignItems: "center",
      rowGap: "2em",
      marginBlock: "3.5em",
      "& img": {
        maxWidth: "500px",
      },
    },
    TemoignagesContainer: {
      flexDirection: "column",
      rowGap: "2em",
    },
    DivPromo: {
      width: "100%",
    },
    DivPromoClassique: {
      width: "100%",
    },
    DivPromoDouble: {
      width: "100%",
    },
    DivPromoBelge: {
      width: "100%",
    },
    AutresOffres: {
      flexDirection: "column",
      rowGap: "2em",
      alignItems: "center",
    },
    Garanties: {
      "& > div": {
        flexDirection: "column",
        rowGap: "1.5rem",
      },
      "& p": {
        fontSize: "1.3rem",
        lineHeight: "1.8rem",
      },
    },
    H3: {
      fontSize: "1.3rem",
      marginBottom: "1em",
    },
    ModalEdt: {
      "& img": {
        maxWidth: "95%",
        height: "unset",
        maxHeight: "90vh",
      },
    },
    ModalForm: {
      "& form": {
        maxWidth: "95%",
        maxHeight: "90vh",
      },
    },
  },

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /* écrans de petite taille = SMARTPHONES */
  "@media only screen and (max-width: 600px)": {
    /*rem : polices - em: autres - se mettre en 320p*/

    PageCursus: {
      fontSize: "1rem",
      lineHeight: "1.6rem",
    },
    Banniere: {
      height: "6.5em",
      "& img": {
        maxHeight: "6.5em",
      },
      marginBottom: "2em",
    },

    H1: {
      fontSize: "1.6rem",
      lineHeight: "2.4rem",

      "& > div:first-child": {
        marginBottom: "1em",
      },
      "& > div:last-child": {
        fontSize: "1.4rem",
      },
      marginBottom: "1.5em",
    },
    VideoEtPromesse: {
      marginBottom: "2em",
    },

    TxtPromesse: {
      maxWidth: "100%",
      fontSize: "1.125rem",
      lineHeight: "2rem",
    },
    Accroche: {
      fontSize: "1.125rem",
      lineHeight: "1.5em",
    },
    ColonnesGauchesContainer: {
      width: "100%",
    },
    H2: {
      fontSize: "1.25rem",
      lineHeight: "2rem",
      marginBottom: "0.8em",
    },
    PetiteAccroche: {
      fontSize: "1.1rem",
      lineHeight: "2rem",
      marginBlock: "2em",
    },
    Illustration: {
      marginBottom: "2rem",
    },
    Liste: {
      "& li": {
        fontSize: "1.05rem",
        lineHeight: "1.6rem",
      },
    },
    Frise: {
      marginBlock: "1rem",
    },

    NiveauxPrimaireBilingue: {
      marginBottom: "2.5em",
      width: "unset",
    },
    CompetencesLangagieres: {
      marginTop: "1.5em",
      marginBottom: "2.5em",
    },
    ApresParagraphe: {
      marginBottom: "2rem",
    },

    PreuveSociale: {
      marginBlock: "2.5em",
      "& img": {
        maxWidth: "100%",
      },
    },
    DivTarifs: {
      fontSize: "1.125rem",
      "& span": {
        fontSize: "1.5rem",
      },
    },

    Garanties: {
      "& > div": {
        rowGap: "1rem",
      },
      "& p": {
        width: "90%",
        marginInline: "auto",
        fontSize: "1.05rem",
      },
    },
    H3: {
      fontSize: "1.125rem",
    },
  },
});
